<template>
  <div>
    <apexchart
      width="100%"
      type="scatter"
      v-if="typeof plots === 'object'"
      :options="plots.options"
      :series="plots.series"
    ></apexchart>
  </div>
</template>

<script>
export default {
  name: 'ScatterGraph',
  props: {
    plots: {
      type: [Object, Array],
      required: true
    }
  },
  components: {
    apexchart: () => import('vue-apexcharts')
  }
}
</script>
